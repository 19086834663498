<template>
  <div>
    <v-container>
      <h1 class="pb-6">Farm Registration</h1>

      <v-card rounded="lg" class="pa-6">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Name of Farm</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Name of Farm Owner</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Farm Address</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Zip/Postal Code</h4>
              <v-text-field outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <h4>District/Region</h4>
              <v-select outlined></v-select>
            </v-col>
            <v-col cols="12">
              <h4>Total Acres of Farm</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>What Are Your Goals and Objectives?</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>
                Farming Activities
              </h4>
              <v-radio-group row>
                <v-radio value="Livestock">
                  <template v-slot:label>
                    <div>
                      Livestock
                    </div>
                  </template>
                </v-radio>
                <v-radio value="Crops">
                  <template v-slot:label>
                    <div>
                      Crops
                    </div>
                  </template>
                  farm-registration
                </v-radio>
                <v-radio value="Both">
                  <template v-slot:label>
                    <div>
                      Both
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <h1 class="py-6">Select Plan</h1>

      <div class="d-flex justify-center pt-4">
        <v-btn-toggle v-model="toggle_exclusive" dark rounded>
          <v-btn color="primary">
            Annual
          </v-btn>

          <v-btn color="primary">
            Monthly
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-card rounded="lg" class="pb-8 my-4">
        <div class="py-8" style="background-color:#E0E0E0">
          <div class="d-flex justify-center">
            <h4>Basic Plan</h4>
          </div>
          <div class="d-flex justify-center">
            <h2>FREE</h2>
          </div>
        </div>
        <div class="px-8 d-flex flex-column pt-4">
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Farm Registration
          </div>
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Livestock, Crop and Equipment Tracking
          </div>
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Basic Reports
          </div>
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Marketplace Access
          </div>
        </div>
        <div class="d-flex justify-center">
          <v-btn color="primary">choose</v-btn>
        </div>
      </v-card>
      <v-card rounded="lg" class="pb-8 my-4">
        <div class="py-8" style="background-color:#E0E0E0">
          <div class="d-flex justify-center">
            <h4>Premium Plan</h4>
          </div>
          <div class="d-flex justify-center">
            <h2>$66/month</h2>
          </div>
        </div>
        <div class="px-8 d-flex flex-column pt-4">
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Farm Registration
          </div>
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Livestock, Crop and Equipment Tracking
          </div>
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Basic Reports
          </div>
          <div class="py-2">
            <v-icon color="primary">mdi-check-circle</v-icon>
            Marketplace Access
          </div>
        </div>
      </v-card>
      <h1 class="py-6">Payment</h1>

      <v-card rounded="lg" class="pb-2 my-4">
        <div class="pa-5">
          <div class="d-flex flex-row">
            <img src="/img/payment/google_pay.svg" />
            <h4 class="pt-3">Google Pay</h4>
          </div>
          <div class="d-flex flex-row pl-2">
            <img width="" src="/img/payment/apple_pay.svg" />
            <h4 class="pt-2 pl-2">Apple Pay</h4>
          </div>
        </div>
      </v-card>

      <v-card rounded="lg" class="pa-6 ">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Card Number</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>Name on Card</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <h4>Expiration Date</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <h4>CVV</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <h4>Amount</h4>
              <v-text-field outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <h4>Currency</h4>
              <v-select outlined></v-select>
            </v-col>

            <v-col cols="12">
              <h4>Email</h4>
              <v-text-field outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <h4>Country or Region</h4>
              <v-text-field outlined></v-text-field>
            </v-col>

            <v-col class="d-flex justify-center" cols="12">
              <div class="text-center">
                <v-bottom-sheet v-model="sheet" inset>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      Pay $500
                    </v-btn>
                  </template>
                  <v-sheet rounded="lg" class="text-center" height="300px">
                    <div class="my-3">
                      <img
                        class="pt-4"
                        src="/img/payment/payment_success.svg"
                      />
                      <h3>Your Payment was Successful!</h3>
                      <p>
                        We have email abc@gmail.com recripe for each invoice
                      </p>
                    </div>
                    <v-btn class="mt-6" color="primary" @click="sheet = !sheet">
                      Return
                    </v-btn>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    sheet: false,
  }),
};
</script>

<style></style>
